<template>
  <div>

    <showben-info :person="person"  />
    <showben-document :person="person.documents" v-if="person.documents"/>
      <show-ben-number :person="person" v-if="person.contact_details" />

     <show-ben-service :personservice="person.proposal_services" v-if="person.proposal_services" />
      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
  </div>
</template>

<script>


import showbenInfo from './show-ben-info.vue'
import showbenDocument from './show-ben-document.vue'
import showBenNumber from './show-ben-number.vue'
import showBenService from './show-ben-service'
import Ripple from "vue-ripple-directive";
import { BButton } from "bootstrap-vue";
export default {
  components: {
  showBenService,
    showbenInfo,
    showBenNumber,
    showbenDocument,
    BButton

  },

  data() {
    return {
     person:{}
    };
  },

  directives: {
    Ripple,
  },

  created() {
    this.get();

  },

  methods: {
    get() {

       let url = `/api/v1/persons/${this.$route.params.id}`;
      //console.log(this.$route.params.id);
      this.$http.get(url).then((res) => {
          console.log("data",res.data.data);

        this.person = res.data.data;


      });
      // //console.log(this.personWeak);
    },



  }
};
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #00468e !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
</style>
